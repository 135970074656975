<template>
  <div class="processDetails">
    <el-dialog
      title="退款订单详情"
      :visible="dialogVisibleDetails"
      @close="consoleDetail"
      width="70%"
    >
      <!-- 退款订单 -->
      <h2 class="parkingInfo">退款订单: {{ refundFlowId }}</h2>
      <el-row>
        <el-col :span="8" class="detailsLeft">
          <div class="detailsItem">
            <span class="detailsSpan">生成时间</span>
            <span>{{ refundFlowVO.applyDateStr }}</span>
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">支付渠道</span>
            <span>{{ refundFlowVO.channelTypeName }}</span>
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">应退实付金额</span>
            <span
              >{{
                refundFlowVO.needRefundActualMoney
                  ? (refundFlowVO.needRefundActualMoney / 100).toFixed(2)
                  : "0.00"
              }}元</span
            >
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">应退优惠金额</span>
            <span
              >{{
                refundFlowVO.alreadyRefundAgioMoney
                  ? (refundFlowVO.alreadyRefundAgioMoney / 100).toFixed(2)
                  : "0.00"
              }}元</span
            >
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">退款尝试次数</span>
            <span>{{ reflength }}次</span>
            <span class="orderNumberStyle" style="cursor: pointer" @click="lookAll">查看</span>
          </div>
        </el-col>
        <el-col :span="8" class="detailsLeft">
          <div class="detailsItem">
            <span class="detailsSpan">退款状态</span>
            <span>{{ refundFlowVO.refundPaymentStateName }}</span>
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">退款方式</span>
            <span>原路退回</span>
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">已退实付金额</span>
            <span
              >{{
                refundFlowVO.alreadyRefundMoney
                  ? (refundFlowVO.alreadyRefundMoney / 100).toFixed(2)
                  : "0.00"
              }}元</span
            >
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">已退优惠金额</span>
            <span
              >{{
                refundFlowVO.alreadyRefundAgioMoney
                  ? (refundFlowVO.alreadyRefundAgioMoney / 100).toFixed(2)
                  : "0.00"
              }}元</span
            >
          </div>
          <div class="detailsItem">
            <span class="detailsSpan">最近尝试时间</span>
            <span>{{ refundFlowVO.lastedTryRefundTimeStr }}</span>
          </div>
        </el-col>
      </el-row>
      <div v-if="!lookAllTag">
        <h2 class="parkingInfo" v-if="businessOrderType == 0">
          支付订单: {{ payOrderVO.payOrderId }}
        </h2>
        <el-row v-if="businessOrderType == 0">
          <el-col :span="8" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">平台商户</span>
              <span>{{ payOrderVO.operationName }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="businessOrderType == 0">
          <el-col :span="8" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">生成时间</span>
              <span>{{ payOrderVO.createdTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">支付渠道</span>
              <span>{{ payOrderVO.channelTypeDesc }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">支付应用</span>
              <span>{{ payOrderVO.devTypeDesc }}</span>
            </div>
          </el-col>
          <el-col :span="8" class="detailsLeft">
            <div class="detailsItem">
              <span class="detailsSpan">支付时间</span>
              <span>{{ payOrderVO.payTime }}</span>
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">优惠金额</span>
              <span
                >{{ payOrderVO.agioPay ? (payOrderVO.agioPay / 100).toFixed(2) : "0.00" }}元</span
              >
            </div>
            <div class="detailsItem">
              <span class="detailsSpan">实付金额</span>
              <span>{{ payOrderVO.money ? (payOrderVO.money / 100).toFixed(2) : "0.00" }}元</span>
            </div>
          </el-col>
        </el-row>

        <h2 class="parkingInfo" v-if="businessOrderType == 0">
          停车订单: {{ parkRecordVO.parkRecordId }}
        </h2>
        <!-- 详情 -->
        <div class="carDetail" v-if="businessOrderType == 0">
          <h2 class="parkingInfo">车场详情</h2>
          <el-row>
            <el-col :span="8" class="detailsLeft">
              <div class="detailsItem">
                <span class="detailsSpan">停车场名称：</span>
                <span>{{ parkRecordVO.parkName }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">商户名称:</span>
                <span>{{ parkRecordVO.operationName }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">车场类型:</span>
                <span>{{ parkRecordVO.parkTypeName }}</span>
              </div>
            </el-col>
            <el-col :span="8" class="detailsLeft">
              <!-- <div class="detailsItem">
                <span class="detailsSpan">城市：</span>
                <span>{{parkRecordVO.areaName}}</span>
              </div> -->
              <div class="detailsItem">
                <span class="detailsSpan">区域：</span>
                <span>{{ parkRecordVO.areaName }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">泊位号：</span>
                <span>{{ parkRecordVO.berthCode }}</span>
              </div>
            </el-col>
          </el-row>
          <h2 class="parkingInfo" v-if="businessOrderType == 0">车辆详情</h2>
          <el-row v-if="businessOrderType == 0">
            <el-col :span="8" class="detailsLeft">
              <div class="detailsItem">
                <span class="detailsSpan">车牌号：</span>
                <span>{{ parkRecordVO.plateNumber }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">入场时间：</span>
                <span>{{ parkRecordVO.strEntryTime }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">入场记录来源：</span>
                <span>{{ parkRecordVO.entryDataSourceName }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">证据图像：</span>
                <span class="orderNumberStyle" style="cursor: pointer" @click="lookPicture"
                  >查看</span
                >
              </div>
            </el-col>
            <el-col :span="8" class="detailsLeft">
              <div class="detailsItem">
                <span class="detailsSpan">停车时长：</span>
                <span>{{ parkRecordVO.parkTime }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">出场时间：</span>
                <span>{{ parkRecordVO.strExitTime }}</span>
              </div>
              <div class="detailsItem">
                <span class="detailsSpan">出场记录来源：</span>
                <span>{{ parkRecordVO.exitDataSourceName }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="lookAllTag">
        <h2 class="parkingInfo">
          退款尝试详情:
          <span
            class="orderNumberStyle"
            style="padding-left: 10px; cursor: pointer"
            @click="lookAll"
            >返回</span
          >
        </h2>
        <!-- 表格 -->
        <el-table  :data="refundOperateRecord" style="width: 100%">
          <el-table-column :label="$t('list.index')" type="index" :index="indexMethod"></el-table-column>
          <el-table-column
            align="center"
            :label="item.label"
            :width="item.width"
            v-for="item in tableDetails"
            :key="item.prop"
            :prop="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>
      <!-- 关闭 -->
      <div class="closewindow">
        <el-button v-show="lookAllTag" @click="lookAll">返回</el-button>
        <el-button @click="consoleDetail">关闭窗口</el-button>
      </div>
    </el-dialog>
    <!-- 证据图像弹框 -->
    <!-- <processPicture
      :outerdatapic="datapic2"
      :enterdatapic="datapic1"
      :dialogVisible="dialogVisiblePic"
      @consolePicture="consolePicture"
    ></processPicture> -->
    <processPicture
      ref="processPictureID"
      :dialogVisible="dialogVisible"
      @consolePicture="consolePicture"
    ></processPicture>
  </div>
</template>
<script>
// import processPicture from "./processPicture";
// import processPicture from '../../../../components/exceptionHanding/processPicture.vue';
import processPicture from "@/components/exceptionHanding/processPicture";
export default {
  name: "picDetail",
  data() {
    return {
      businessOrderType: 0,
      dialogVisible: false, // 证据图像弹框
      lookAllTag: false, // 修改信息弹框
      tableDetails: [
        {
          prop: "refundMoney",
          label: "退款金额(元)",
          formatter: (row, column) => {
            if (row.refundMoney) {
              return Number(row.refundMoney / 100).toFixed(2) + "元";
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "operateTime",
          label: "尝试时间",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
        },
        {
          prop: "refundStateDesc",
          label: "结果",
        },
      ],
      refundFlowVO: {}, // 详细信息
      refundOperateRecord: [], // 操作记录
      payOrderVO: {}, // 车场记录
      datapic1: [],
      datapic2: [],
      parkRecordVO: {},
      reflength: "",
    };
  },
  watch: {},
  components: {
    processPicture,
  },
  mounted() {},
  computed: {},
  methods: {
    indexMethod(index) {
      return this.refundOperateRecord.length - index;
    },
    // 查看证据图片
    lookPicture() {
      this.dialogVisible = true;
      this.$refs.processPictureID.getPic(
        this.parkRecordVO.entryOprNum,
        this.parkRecordVO.exitOprNum
      );
    },
    consolePicture() {
      this.dialogVisible = false;
    },
    // 查看退款次数
    lookAll() {
      this.lookAllTag = !this.lookAllTag;
    },
    consoleDetail() {
      this.$emit("consoleDetails");
      // this.$emit('update:visible', false)
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    // 订单修改明细详情查询
    searParkRecordListFun(data, businessOrderType) {
      console.log("data", data);
      this.businessOrderType = businessOrderType;
      let opt = {
        method: "get",
        // url: "/acb/2.0/refundFlow/detail",
        url: "/acb/2.0/refundOrder/detail",
        data: {
          refundOrderId: data,
        },
        success: (res) => {
          if (res.state == 0) {
            this.refundFlowVO = res.value;
            this.refundOperateRecord = res.value.refundOperateRecordVOList;
            this.payOrderVO = res.value.payOrderVO || {};
            this.parkRecordVO = res.value.parkRecordVO || {};
            this.reflength = res.value.refundOperateRecordVOList.length;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
  },
  props: {
    dialogVisibleDetails: {
      default: false,
    },
    refundFlowId: {
      type: String,
      default: "",
    },
  },
  created() {
    // this.searParkRecordListFun();
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.timelineStyle {
  padding-left: 150px;
}

.picBox {
  width: 200px;
  height: 150px;
  padding: 5px;
}

.bottomLine {
  border-bottom: 1px solid #F4F6FA;
}

.stateTip {
  z-index: 9999;
  background-color: #F4F6FA;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #0f6eff;
  border-radius: 10px;
  font-weight: 700;
}

.detailsItem {
  margin-bottom: 10px;
  padding-left: 30px;
}

.detailsSpan {
  width: 150px;
  text-align: left;
  display: inline-block;
}

.orderNumberStyle {
  color: #0f6eff;
}

.closewindow {
  display: flex;
  justify-content: right;
  margin-top: 10px;
}

.parkingInfo {
  font-family: 'PingFang';
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  border-bottom: 1px solid #DCDFE6;
}

.parkingInfo-on {
  position: absolute;
  left: 0;
  top: 31%;
  width: 4px;
  height: 16px;
  background: #0099fa;
}

.ustyleLine {
  text-decoration: line-through;
}

.uStyleItem {
  color: #0f6eff;
}
</style>
<style lang="stylus">
.processDetails .el-dialog__body {
  padding-top: 4px;
}

.processDetails .el-dialog__header {
  padding: 0 12px;
  background-color: #3F4A56;
  height: 40px;
  line-height: 40px;
  border-radius: 3px 3px 0 0;

  .el-dialog__title {
    font-size: 14px;
    color: #fff;
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #ffffff;
    transform: translateY(-50%);
  }
}

.processDetails .el-step.is-center .el-step__line {
  left: 50%;
  right: -63%;
}

.carDetail {
  padding: 0 20px;
}
</style>
